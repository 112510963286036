import React, { createContext, useState, useContext } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [streamId, setStreamId] = useState(null);

  const showPopup = () => setIsPopupVisible(true);
  const hidePopup = () => setIsPopupVisible(false);
  const updateStreamId = (id) => setStreamId(id);

  return (
    <PopupContext.Provider value={{ isPopupVisible, showPopup, hidePopup, streamId, updateStreamId }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopupContext = () => {
  return useContext(PopupContext);
};
