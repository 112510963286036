/* eslint-disable indent */
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { handleKeyStrokes } from '@services/utils.service';
import { pushDataLayerForEvent } from '@lib/gtag';
import Button from '../atoms/Button';
import Card from '../atoms/Card';
import Heading from '../atoms/Heading';
import Image from '../atoms/Image';
import { Skeleton } from '@nextui-org/react';
import ButtonNew from '@atoms/Button.new';

export const platformImages = {
  zoom: '/images/Zoom.svg',
  teams: '/images/teams-logo.svg',
  youtube: '/images/youtube-icon.svg',
  ipa: '/images/in_person.svg',
  meet: '/images/meet-icon.svg',
  twitch: '/images/twitch-icon.svg',
  'other-online-events': '/images/OtherOnlinePlatforms.svg',
  hybrid: '/images/hybrid.svg',
  vimeo: '/images/vimeo-icon.svg',
  webex: '/images/webex-icon.svg',
  linkedin: '/images/linkedin-icon.svg',
  restream: '/images/restream.svg',
  streamyard: '/images/streamyard.png',
  airmeet: '/images/airmeet.svg',
  kick: '/images/kick.png'
};

const CardList = ({
  stream,
  totalStream,
  fetchMoreData,
  goToFantasticPage,
  page,
  isLoading
}) => <>
    <div className='p-4 flex items-center'>
      <div className='flex items-center w-full'>
        <div className='text-sm  font-semibold text-grey-900 max-w-96'>Stream</div>
      </div>
      <div className='flex items-center'>
        <div className='text-sm text-grey-900  font-semibold  w-44'>Time</div>
        <div className='text-sm text-grey-900  font-semibold  w-44'>Date</div>
        <div className='text-sm text-grey-900  font-semibold  w-18 text-left'>Action</div>
      </div>
    </div>
    <div id="scrollableDivTwo" className="overflow-y-auto h-[calc(100%-140px)]">
      {isLoading && <>
        <Skeleton className="h-20 rounded-lg" />
        <Skeleton className="h-20 mt-4 rounded-lg" />
      </>}
      {!isLoading && <InfiniteScroll
        dataLength={stream.length}
        next={fetchMoreData}
        hasMore={totalStream >= stream.length}
        loader={totalStream > stream.length && <h4 className='text-grey-700 text-sm animate-blink w-full text-center'>Loading...</h4>}
        scrollableTarget="scrollableDivTwo"
      >
        {
          stream.map((obj) => <Card
            onClick={() => {
              const analyticsStreamId = obj.id;
              pushDataLayerForEvent('analyze analytics', { analyticsStreamId });
              goToFantasticPage(analyticsStreamId);
              localStorage.setItem('analyticsScrollId', JSON.stringify({ id: `${analyticsStreamId}`, page }));
            }}
            tabIndex={2}
            onKeyDown={handleKeyStrokes}
            key={obj.id} id={obj.id}
            bgColor={'bg-white'}
            className={'py-4 px-4 mb-4 ml-0.5 mr-0.5 flex rounded-md shadow-none border-1 border-grey-200 hover:bg-grey-50 hover:transition-all cursor-pointer'}
            background="bg-none">
            <div className='flex items-center w-full pr-2'>
              <Image src={platformImages[obj.platform_type]} width={32} height={16.93} alt={`${obj.platform_type}-icon`} className="mr-3 flex-shrink-0" />
              <Heading className={'text-sm text-grey-900 font-semibold max-w-96 truncate overflow-hidden'}>
                {obj.title}
              </Heading>
            </div>
            <div className='flex items-center'>
              <div className='text-sm text-grey-800 font-normal leading-5 text-justify  w-44'>{obj.stream_totalTime}</div>
              <div className='text-sm text-grey-800 font-normal leading-5 text-justify  w-44'>{obj.stream_date}</div>

              <div className='w-18'>
                <ButtonNew
                  sm
                  id="analyzeAnalyticsId"
                  tabIndex={2}
                  autoLoaderTimeout={process.env.NEXT_PUBLIC_DEFAULT_LOADER_TIMEOUT}
                  variant='secondary'
                >
                  View
                </ButtonNew>
              </div>
            </div>
          </Card>)
        }
      </InfiniteScroll>}
    </div>
  </>;

export default CardList;
