/* eslint-disable camelcase */
/* eslint-disable no-return-assign */
import qs from 'qs';

export const getApiBase = () => {
  if (process.env.NEXT_PUBLIC_APP_ENV === 'development' && (typeof window !== 'undefined')) {
    return '/api/';
  }
  return process.env.NEXT_PUBLIC_API_URL;
};

export const getBase = () => process.env.NEXT_PUBLIC_URL;

// eslint-disable-next-line default-param-last
export const UrlParamsReplace = (url, params = {}, queryParams) => {
  let urlWithPrefix = `${getApiBase()}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  if (queryParams) {
    urlWithPrefix += `?${qs.stringify(queryParams)}`;
  }
  return urlWithPrefix;
};

export const GET_USER_ACCOUNT_URL = () => UrlParamsReplace('userAccount');
export const GET_ME_URL = () => UrlParamsReplace('auth/me');
export const GET_USER_TEAMS_URL = () => UrlParamsReplace('userTeams');
export const GET_USER_DETAILS_URL = (id) => UrlParamsReplace('user-details/:id', { id });
export const UPDATE_USER_DETAILS = (userAccId) => UrlParamsReplace('userProfile/:userAccId', { userAccId });
export const GET_PLATFORM_ID_BY_USER_ID_URL = (id, platformType) => UrlParamsReplace('user-platforms/:id?platformType=:platformType', { id, platformType });
export const GET_YOUTUBE_STREAMS_URL = (id, hybrid = false) => UrlParamsReplace('youtube-platforms/:id/streams?hybrid=:hybrid', { id, hybrid });
export const CONNECT_TO_YOUTUBE_STREAM_URL = () => UrlParamsReplace('streams/connect');
export const GET_LIVESTREAM_URL = (id) => UrlParamsReplace('streams/:id', { id });
export const GET_LIVESTREAM_URL_NO_AUTH = (id) => UrlParamsReplace('streams/no-auth/:id', { id });
export const GET_ANALYTICS_URL = (streamId) => UrlParamsReplace('analytics/streams/:streamId', { streamId });
export const GET_CUMULATIVE_HEATMAP_ANALYTICS_URL = () => UrlParamsReplace('analytics/cumulative-heatmap');
export const GET_IPA_EVENT_BY_ID_URL = (id) => UrlParamsReplace('platforms/ipa/events/:id', { id });
export const DISCONNECT_YOUTUBE_STREAM_URL = (id) => UrlParamsReplace('streams/:id/disconnect', { id });
export const UPDATE_ENGAGEMENT_TYPE_URL = (id, engageType) => UrlParamsReplace('streams/:id/:engageType', { id, engageType });
export const CREATE_ENGAGEMENT_URL = (id, engageType) => UrlParamsReplace('streams/:id/:engageType', { id, engageType });
export const STOP_ENGAGEMENT_URL = (id, engageType) => UrlParamsReplace('streams/:id/:engageType', { id, engageType });
export const GET_ENGAGEMENT_PROCESSED_COMMENTS = (id, engageType) => UrlParamsReplace('streams/:id/:engageType/processed-comments', { id, engageType });
export const GET_QUICK_QUESTIONS = (streamId, isReport) => UrlParamsReplace('quick-questions/:streamId?isReport=:isReport', { streamId, isReport });
export const UPDATE_LIVESTREAM_SESSION_URL = (sessionId) => UrlParamsReplace('stream-session/:sessionId', { sessionId });

export const TEAM_URL = (id) => UrlParamsReplace('teams/:id', { id });
export const TEAM_MEMBERS_URL = (id) => UrlParamsReplace('teams/:id/members', { id });
export const TEAM_MEMBERS_DETAIL_URL = (id, eventId) => UrlParamsReplace('teams/:eventId/members/:id', { id, eventId });
export const TEAM_GENERATE_TOKEN_URL = () => UrlParamsReplace('teams/token');
export const TEAM_VERIFY_TOKEN_URL = () => UrlParamsReplace('teams/verify-token');

export const GET_DEFAULT_COLORS = () => UrlParamsReplace('DefaultColors');
export const GET_CUSTOM_COLORS = (id) => UrlParamsReplace('userProfile/:id', { id });
export const UPDATE_COLORS = (userAccId) => UrlParamsReplace('userProfile/:userAccId', { userAccId });
export const REQUEST_BETA_ACCESS_URL = () => UrlParamsReplace('betaAccess');
export const DEAUHTORIZE_YOUTUBE_URL = (id) => UrlParamsReplace('platforms/:id/deauthorization', { id });
export const GET_PLATFORM_BY_USER_ID = (id) => UrlParamsReplace('users/:id/platforms', { id });
export const CREATE_LIVE_STREAM_URL = () => UrlParamsReplace('streams');
export const GET_TEAMS_MEETINGS_URL = (id, email, startDate, endDate, hybrid = false) => UrlParamsReplace('platforms/teams-platform/:id/meetings?email=:email&startDate=:startDate&endDate=:endDate&hybrid=:hybrid', {
  id, email, startDate, endDate, hybrid
});
export const UPDATE_STREAM_SETTINGS = (id) => UrlParamsReplace('streams/:id/settings', { id });
export const CREATE_QUESTION_URL = (id) => UrlParamsReplace('streams/:id/questions', { id });
export const GET_QUESTIONS_URL = (userId, type) => UrlParamsReplace('users/:userId/questions?type=:type', { userId, type });
export const GET_QUESTIONS_BY_LIVESTREAM_URL = (streamId, engagementTyoe) => UrlParamsReplace('streams/:streamId/questions/:engagementTyoe', { streamId, engagementTyoe });
export const CREATE_OPTION_URL = (id) => UrlParamsReplace('streams/questions/:id/options', { id });
export const UPDATE_OPTION_URL = (id) => UrlParamsReplace('streams/questions/options/:id', { id });
export const DELETE_OPTION_URL = (questionId, id) => UrlParamsReplace('streams/questions/:questionId/options/:id', { questionId, id });
export const DELETE_QUESTION_URL = (id) => UrlParamsReplace('streams/questions/:id', { id });
export const UPDATE_QUESTION_URL = (id) => UrlParamsReplace('streams/questions/:id', { id });
export const UPDATE_ENGAGEMENT_URL = (id, engageType) => UrlParamsReplace('engagement-features/:id/:engageType', { id, engageType });
export const GET_QUESTION_URL = (questionId) => UrlParamsReplace('questions/:questionId', { questionId });
export const DELETE_BETA_REQUEST_URL = (id) => UrlParamsReplace('admin/betaAccess/:id', { id });
export const GET_COMMENT_URL = (streamId, pageNumber) => UrlParamsReplace(`comments/:streamId?page=${pageNumber}`, { streamId });
export const GET_ZOOM_MEETING_URL = (streamId) => UrlParamsReplace('platforms/zoom-platform/:streamId/meeting', {
  streamId
});
export const GET_ZOOM_MEETINGS_URL = (id, type, startDate, endDate) => UrlParamsReplace('platforms/zoom-platform/:id/meetings?type=:type&startDate=:startDate&endDate=:endDate', {
  id, type, startDate, endDate
});

export const GET_IPA_EVENTS_URL = ({ offset, limit, event_type }) => UrlParamsReplace('platforms/ipa/events?offset=:offset&limit=:limit&event_type=:event_type', {
  limit, offset, event_type
});

export const COPILOT_RESTRICT_COPILOT = (pathname) => UrlParamsReplace('copilot/checkCopilot/?pathname=:pathname', { pathname });

export const CREATE_IPA_PLATFORM_URL = () => UrlParamsReplace('platforms/ipa');
export const POST_IPA_COPILOT_URL = (id) => UrlParamsReplace('platforms/ipa/events/:id/copilots', { id });
export const POST_IPA_GENERIC_COPILOT_URL = (id) => UrlParamsReplace('platforms/ipa/events/:id/copilots/generic', { id });
export const DELETE_IPA_EVENT_URL = (id) => UrlParamsReplace('platforms/ipa/events/:id', { id });
export const POST_IPA_USER_EVENT_URL = () => UrlParamsReplace('platforms/ipa/events');
export const SHARE_IPA_USER_EVENT_DETAILS_URL = (eventId) => UrlParamsReplace('platforms/ipa/events/:eventId/share', { eventId });
export const UPDATE_IPA_EVENT_URL = (id) => UrlParamsReplace('platforms/ipa/events/:id', { id });
export const GET_IPA_EVENT_BY_SLUG_URL = (slug) => UrlParamsReplace('platforms/ipa/events/slug/:slug', { slug });
export const CREATE_IPA_AUTH_URL = () => UrlParamsReplace('platforms/ipa/auth');

export const CREATE_ZOOM_PLATFORM_URL = () => UrlParamsReplace('platforms/zoom-platform');
export const ZOOM_APP_INSTALL_URL = () => UrlParamsReplace('platforms/zoom/install');
export const GET_LIVESTREAM_BY_USER_URL = (userId, page) => UrlParamsReplace(`analytics/streams/:userId?page=${page}`, { userId });

export const POST_ZOOM_COMMENT_URL = () => UrlParamsReplace('zoom-comments');
export const GET_QUICK_QUESTIONS_PAGINATED = (streamId, queryParams) => UrlParamsReplace('quick-questions/:streamId', { streamId }, queryParams);
export const UPDATE_FREEFROM_POLL_URL = (id) => UrlParamsReplace('poll/:id', { id });
export const GET_FREEFORM_POLL_COMMENT_URL = (id) => UrlParamsReplace('poll/comment/:id', { id });
export const GET_QUICK_QUESTIONS_URL = (streamId) => UrlParamsReplace('quick-questions/:streamId', { streamId });
export const UPDATE_QUICK_QUESTION_URL = () => UrlParamsReplace('quick-questions');
export const GET_DEFAULT_SETTINGS = () => UrlParamsReplace('DefaultSettings');
export const POST_ZOOM_USER_MEETINGS_URL = (platformId) => UrlParamsReplace('platforms/:platformId/user-meetings', { platformId });

export const LOG_TO_AWS_CLOUDWATCH_URL = () => UrlParamsReplace('log-to-aws-cloudwatch');

export const CREATE_ROS_QUESTION_URL = () => UrlParamsReplace('ros/questions');
export const CREATE_ROS_TEMPLATE_URL = (title, userId) => UrlParamsReplace('ros/templates?title=:title&userId=:userId', { title, userId });
export const SANDBOX_GET_ROS_TEMPLATE_URL = (hash) => UrlParamsReplace('ros/sandbox/:hash', { hash });
export const GET_TEMPLATES_URL = (userId, queryParams) => UrlParamsReplace('ros/templates/:userId', { userId }, queryParams);
export const CREATE_ROS_OPTION_URL = () => UrlParamsReplace('ros/options');
export const DELETE_ROS_TEMPLATE_URL = (id) => UrlParamsReplace('ros/templates/:id', { id });
export const GET_ALL_TEMPLATE_URL = (userId) => UrlParamsReplace('templates/:userId', { userId });
export const GET_LINK_QUESTIONS_URL_PAGINATED = (streamId, queryParams) => UrlParamsReplace('link-library/:streamId', { streamId }, queryParams);
export const UPDATE_ROS_QUESTION_URL = (id) => UrlParamsReplace('ros/questions/:id', { id });
export const UPDATE_ROS_QUESTIONS_URL = (id) => UrlParamsReplace('questions/:id', { id });
export const GET_LINK_QUESTIONS_URL = (id) => UrlParamsReplace('link-questions/:id', { id });
export const GET_LINK_ROS_QUESTIONS_STATUS_URL = (streamId) => UrlParamsReplace('linkQuestions/:streamId', { streamId });
export const DELETE_OPTIONS_URL = (id) => UrlParamsReplace('ros/options/:id', { id });
export const GET_CHAT_ID_FOR_TEAM_URL = (platformId, entityId) => UrlParamsReplace(`teams-url/:platformId?entityId=${entityId}`, { platformId });

export const DELETE_POLLS_URL = (pollId) => UrlParamsReplace('polls/:pollId', { pollId });
export const UPDATE_COMMENTS_URL = (featureId) => UrlParamsReplace('update-comments/:featureId', { featureId });
export const DELETE_WORDCLOUDS_URL = (wordCloudId) => UrlParamsReplace('wordClouds/:wordCloudId', { wordCloudId });
export const DELETE_MAPS_URL = (mapId) => UrlParamsReplace('maps/:mapId', { mapId });
export const UPDATE_LIVE_STREAM_URL = (id) => UrlParamsReplace('update-streams/:id', { id });
export const GET_ADMIN_SETTINGS = () => UrlParamsReplace('adminSettings');
export const UPDATE_ADMIN_SETTINGS = () => UrlParamsReplace('adminSettings');
export const UPDATE_WORD_CLOUD_URL = (id) => UrlParamsReplace('wordCloud/:id', { id });
export const GET_ENGAGEMENT_FEATURE_URL = (id, engageType) => UrlParamsReplace('streams/:id/:engageType', { id, engageType });
export const GET_TEMPLATE_TITLE_URL = (tempId, userId) => UrlParamsReplace(`runofshow/templates/:tempId${userId ? '?userId=:userId' : ''}`, { tempId, userId });
export const USER_LOGIN_URL = () => UrlParamsReplace('login');
export const USER_VERIFY_URL = () => UrlParamsReplace('login/verify');
export const getStreamsForPage = (userId, page) => UrlParamsReplace(`livestreams/:userId?${page}`, { userId });
export const GET_MAP_PROCESSED_COMMENTS_COUNT = (mapId) => UrlParamsReplace('map/comments/:mapId', { mapId });
export const SEND_ANALYTICS_REPORTS_TO_USER = (streamId) => UrlParamsReplace('analytics-reports/:streamId', { streamId });
export const GET_FEATURE_BY_QID = (questionId, streamId, type) => UrlParamsReplace('features/:questionId/:streamId/:type', {
  questionId, streamId, type
});

export const GET_STREAM_RESULT = (streamId) => UrlParamsReplace('result/:streamId', { streamId });
export const UPDATE_LIVE_STREAM = (id) => UrlParamsReplace('streams/:id', { id });
export const UPDATE_LIVE_STREAM_SETTINGS = (id) => UrlParamsReplace('streams/:id/settings', { id });

export const UPDATE_FREE_FORM_Poll = (id) => UrlParamsReplace('poll/:id', { id });
// presentation slides
export const CREATE_PRESENTATION_SLIDE_URL = () => UrlParamsReplace('presentations');
export const UPDATE_PRESENTATION_SLIDE = () => UrlParamsReplace('presentations/update');
export const GET_PRESENTATION_SLIDES = (userId) => UrlParamsReplace('presentations/:userId', { userId });
export const DELETE_UPLOADED_PRESENTATION_DOCUMENT = (userId, fileName) => UrlParamsReplace(`presentations/:userId?fileName=${fileName}`, { userId });

// Billing
export const CREATE_CHECKOUT_SESSION = () => UrlParamsReplace('create-checkout-session');
export const UPDATE_BILLING_PLAN = (billingAccId) => UrlParamsReplace('update-billing-plan/:billingAccId', { billingAccId });
export const GET_STRIPE_UPCOMING_INVOICE = (subscriptionId, newPlan) => UrlParamsReplace(`stripe-subscriptions/:subscriptionId?newPlan=${newPlan}`, { subscriptionId });
export const SCHEDULE_SUBSCRIPTION_DOWNGRADE = (subscriptionId) => UrlParamsReplace('stripe-subscriptions-schedule/:subscriptionId', { subscriptionId });
export const RENEW_SUBSCRIPTION = (subscriptionId) => UrlParamsReplace('stripe-subscriptions-renew/:subscriptionId', { subscriptionId });

export const UPDATE_ZOOM_MEETING = (id) => UrlParamsReplace('platforms/zoom/:id', { id });
export const UPDATE_LIVE_STREAM_DATA = (id) => UrlParamsReplace('streams/:id', { id });
export const WAKE_UP_LAMBDA_URL = () => UrlParamsReplace('lambda');
export const DELETE_ZOOM_STREAMS = (id) => UrlParamsReplace('zoom/stream/:id', { id });

export const OPENAI_QUESTIONS = () => UrlParamsReplace('openai/questions');
export const UPDATED_INTERESTED_PLATFORMS = () => UrlParamsReplace('users/platforms');
export const CREATE_MEET_PLATFORM_URL = () => UrlParamsReplace('meet-platforms');
export const CREATE_MEET_USER_MEETING = (platformId) => UrlParamsReplace('meet-platforms/:platformId/meetings', { platformId });
export const DELETE_MEET_MEETING = (id) => UrlParamsReplace('meet-platforms/meetings/:id', { id });
export const UPDATE_MEET_MEETING = (id) => UrlParamsReplace('meet-platforms/meetings/:id', { id });
export const GET_MEET_MEETING_URL = (streamId) => UrlParamsReplace('meet-platforms/:streamId/meeting', {
  streamId
});
export const GET_MEET_MEETINGS_URL = (platformId, startDate, endDate) => UrlParamsReplace('meet-platforms/:platformId/meetings?startDate=:startDate&endDate=:endDate', {
  platformId, startDate, endDate
});

export const GET_COMMENTS_DETAILS = (streamId, wheelUser) => UrlParamsReplace(`wheels/:streamId?user=${wheelUser}`, { streamId });
export const GENERATE_ZOOM_SIGNATURE_URL = (meetingId, platformId) => UrlParamsReplace('platforms/zoom-platform/signature?meetingId=:meetingId&platformId=:platformId', { meetingId, platformId });

export const GET_FEATURE_COMMENTS_DETAILS = (streamId, featureId, wheelShowUser) => UrlParamsReplace(`wheels/:streamId/:featureId?user=${wheelShowUser}`, { streamId, featureId });
export const GET_LINK_LIBRARY_URL = (streamId, skip, filter) => UrlParamsReplace('link-library/:streamId?skip=:skip&filter=:filter', { streamId, skip, filter });
export const DELETE_LINK_LIBRARY_URL = (id) => UrlParamsReplace('link-library/:id', { id });
// Twitch
export const GET_TWITCH_STREAMS_URL = (id) => UrlParamsReplace('twitch-platforms/:id/streams', { id });
export const UPDATE_EMOJI_EVERYWHERE = (id) => UrlParamsReplace('emojis-everywhere/:id', { id });

export const GET_TEXT_TRACK_COMMENTS = (streamId, profanityFilter) => UrlParamsReplace('text-track/:streamId?profanityFilter=:profanityFilter', { streamId, profanityFilter });

export const CREATE_VIMEO_PLATFORM_URL = () => UrlParamsReplace('platforms/vimeo');
export const GET_VIMEO_MEETINGS_URL = (platformId, startDate, endDate) => UrlParamsReplace('platforms/vimeo/:platformId/meetings?startDate=:startDate&endDate=:endDate', {
  platformId, startDate, endDate
});
export const CREATE_VIMEO_USER_MEETING = (platformId) => UrlParamsReplace('platforms/vimeo/:platformId/meetings', { platformId });
export const DELETE_VIMEO_MEETING = (id) => UrlParamsReplace('platforms/vimeo/meetings/:id', { id });
export const UPDATE_VIMEO_MEETING = (id) => UrlParamsReplace('platforms/vimeo/meetings/:id', { id });
export const GUEST_LOGIN_URL = () => UrlParamsReplace('login/guest-mode');

export const CREATE_WEBEX_PLATFORM_URL = () => UrlParamsReplace('platforms/webex');
export const GET_WEBEX_MEETINGS_URL = (platformId, startDate, endDate) => UrlParamsReplace('platforms/webex/:platformId/meetings?startDate=:startDate&endDate=:endDate', {
  platformId, startDate, endDate
});
export const CREATE_WEBEX_USER_MEETING = (platformId) => UrlParamsReplace('platforms/webex/:platformId/meetings', { platformId });
export const DELETE_WEBEX_MEETING = (id) => UrlParamsReplace('platforms/webex/meetings/:id', { id });
export const UPDATE_WEBEX_MEETING = (id) => UrlParamsReplace('platforms/webex/meetings/:id', { id });
export const GENERATE_PRE_SIGNED_URLS = () => UrlParamsReplace('aws/generate-presigned-urls');
export const CONVERT_PDF_TO_IMAGE_URL = () => UrlParamsReplace('aws/convert-pdf-to-image');
export const CONVERT_FILE_TO_PDF = () => UrlParamsReplace('aws/convert-file-to-pdf');

export const SEND_MAX_PARTICIPANTS_ALERT = (streamId) => UrlParamsReplace('max-participants-alert/:streamId', { streamId });
export const GET_ANALYTICS = (userId, page) => UrlParamsReplace('livestream/:userId?page=:page', { userId, page });

export const SEND_MAX_TIME_ALERT = (streamId) => UrlParamsReplace('max-time-alert/:streamId', { streamId });

export const CREATE_LINKEDIN_PLATFORM_URL = () => UrlParamsReplace('platforms/linkedin');
export const GET_LINKEDIN_MEETINGS_URL = (platformId, startDate, endDate) => UrlParamsReplace('platforms/linkedin/:platformId/meetings?startDate=:startDate&endDate=:endDate', {
  platformId, startDate, endDate
});
export const CREATE_LINKEDIN_USER_MEETING = (platformId) => UrlParamsReplace('platforms/linkedin/:platformId/meetings', { platformId });
export const DELETE_LINKEDIN_MEETING = (id) => UrlParamsReplace('platforms/linkedin/meetings/:id', { id });
export const UPDATE_LINKEDIN_MEETING = (id) => UrlParamsReplace('platforms/linkedin/meetings/:id', { id });
export const GET_FANTASTIC_FANS_PAGINATED = (streamId) => UrlParamsReplace('fantastic-fans/:streamId', { streamId });
export const GET_SCREENSHOTS = (streamId) => UrlParamsReplace('screenshots/:streamId', { streamId });
export const GET_SCREENSHOTS_PDF = () => UrlParamsReplace('screenshots-pdf');
export const GET_CELLO_JWT_TOKEN = (userId) => UrlParamsReplace('cello/generate-token/:userId', { userId });
export const GET_ACTIVE_LINK_LIVESTREAM_URL = (userId) => UrlParamsReplace('link-livestream/:userId', { userId });
export const GET_QUESTION_BY_LINK_ID = (link_id) => UrlParamsReplace('question-by-link-id/:link_id', { link_id });
export const GET_SCREENSHOT_DETAILS = (stream_id, screenshot_id) => UrlParamsReplace('screenshots/:stream_id/:screenshot_id', { stream_id, screenshot_id });
export const CONVERT_FILE_TO_IMAGES = () => UrlParamsReplace('slides/convert');

export const CREATE_RESTREAM_PLATFORM_URL = () => UrlParamsReplace('platforms/restream');
export const GET_RESTREAM_MEETINGS_URL = (platformId, startDate, endDate) => UrlParamsReplace('platforms/restream/:platformId/meetings?startDate=:startDate&endDate=:endDate', {
  platformId, startDate, endDate
});
export const CREATE_RESTREAM_USER_MEETING = (platformId) => UrlParamsReplace('platforms/restream/:platformId/meetings', { platformId });
export const DELETE_RESTREAM_MEETING = (id) => UrlParamsReplace('platforms/restream/meetings/:id', { id });
export const UPDATE_RESTREAM_MEETING = (id) => UrlParamsReplace('platforms/restream/meetings/:id', { id });

export const CREATE_STREAMYARD_PLATFORM_URL = () => UrlParamsReplace('platforms/streamyard');
export const GET_STREAMYARD_MEETINGS_URL = (platformId, startDate, endDate) => UrlParamsReplace('platforms/streamyard/:platformId/meetings?startDate=:startDate&endDate=:endDate', {
  platformId, startDate, endDate
});
export const CREATE_STREAMYARD_USER_MEETING = (platformId) => UrlParamsReplace('platforms/streamyard/:platformId/meetings', { platformId });
export const DELETE_STREAMYARD_MEETING = (id) => UrlParamsReplace('platforms/streamyard/meetings/:id', { id });
export const UPDATE_STREAMYARD_MEETING = (id) => UrlParamsReplace('platforms/streamyard/meetings/:id', { id });

export const CREATE_AIRMEET_PLATFORM_URL = () => UrlParamsReplace('platforms/airmeet');
export const GET_AIRMEET_MEETINGS_URL = (platformId, startDate, endDate) => UrlParamsReplace('platforms/airmeet/:platformId/meetings?startDate=:startDate&endDate=:endDate', {
  platformId, startDate, endDate
});
export const CREATE_AIRMEET_USER_MEETING = (platformId) => UrlParamsReplace('platforms/airmeet/:platformId/meetings', { platformId });
export const DELETE_AIRMEET_MEETING = (id) => UrlParamsReplace('platforms/airmeet/meetings/:id', { id });
export const UPDATE_AIRMEET_MEETING = (id) => UrlParamsReplace('platforms/airmeet/meetings/:id', { id });

export const GET_PRESENTATION_SLIDES_FIRST_PAGE = (userId) => UrlParamsReplace('presentation-page-one/:userId', { userId });
export const GET_PRESENTATION_SLIDES_BY_NAME = (userId, name) => UrlParamsReplace('presentation-by-name/:userId/:name', { userId, name });

export const CREATE_KICK_PLATFORM_URL = () => UrlParamsReplace('platforms/kick');
export const GET_KICK_MEETINGS_URL = (platformId, startDate, endDate) => UrlParamsReplace('platforms/kick/:platformId/meetings?startDate=:startDate&endDate=:endDate', {
  platformId, startDate, endDate
});
export const CREATE_KICK_USER_MEETING = (platformId) => UrlParamsReplace('platforms/kick/:platformId/meetings', { platformId });
export const DELETE_KICK_MEETING = (id) => UrlParamsReplace('platforms/kick/meetings/:id', { id });
export const UPDATE_KICK_MEETING = (id) => UrlParamsReplace('platforms/kick/meetings/:id', { id });
